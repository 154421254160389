.toggle {
  margin: 1rem;
  border: 2px solid #000;
}

.toggle-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #fff;
  justify-content: space-between;
}

.toggle-title {
  font-size: 2rem;
}

.toggle-button {
  display: block;
  border: none;
  background: transparent;
  font-size: 2rem;
  font-weight: bold;
  background: #000;
  color: #fff;
  padding: 0;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.toggle-content {
  border-top: 2px solid #000;
  padding: 2rem;
}
