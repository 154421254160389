.Question-content {
  position: relative;
}

.Question-content-offline {
  background: rgba(0, 0, 0, 0.75);
  user-select: none;
  position: absolute;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}

.carLabel {
  background-color: #001457;
}

.Question-title {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 110%;
}

.Question-header {
  top: 0;
  left: 0;
  z-index: 2;
}

.Question-answer label {
  display: flex;
}

.Question-content {
}

input[type="radio"], input[type="checkbox"] {
  -webkit-appearance: none;
  border: solid 2px #e4e4e4;
  padding: .625rem;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
}

input[type="radio"]:hover, input[type="checkbox"]:hover {
  border: solid 2px #001457;
  cursor: pointer;
}

input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline:none;
}

input[type="radio"]:checked, input[type="checkbox"]:checked {
  -webkit-appearance: none;
  border: solid 2px #fff;
  padding: .625rem;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  background-color: #001457;
  /* outline: solid 2px #001457; */
  box-shadow: 0 0 0 2px #001457;
}

label:hover {
  cursor: pointer;
}
