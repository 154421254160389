.Quizzes {
  background: #fff;
  padding: 40px 0;
  margin: 0 auto;
  max-width: 100%;
  align-items: center;
  text-align: center;
  display: flex;
  grid-gap: 30px;
  flex-direction: column;
  height: calc(100vh - 64px);
}

.buttonList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.PartnersLogo {
  max-width: 600px;
}

.QuizzesButton {
  display: inline-flex;
  padding: 20px 40px;
  line-height: 20px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 700;
  background: #001457;
  color: #fff;
  margin: 10px 20px;
}
