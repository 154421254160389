.Button {
  background: #001457;
  cursor: pointer;
  color: #fff;
  border: 0;
  height: 3.5rem;
  line-height: 3.5rem;
  display: block;
  border-radius: 1.75rem;
  outline: none;
}

.Button-small {
  cursor: pointer;
  color: #fff;
  border: 0;
  height: 2rem;
  line-height: 2rem;
  display: block;
  border-radius: 1rem;
  outline: none;
  padding: 0 1rem;
}

.Button-xs {
  border: 0;
  height: 2rem;
  line-height: 2rem;
  display: block;
  border-radius: 1rem;
  outline: none;
  padding: 0 1rem;
}

.Button-admin {
  border: 0;
  border-radius: 1rem;
  outline: none;
  float: left;
}

.edit-button {
  color: #fff;
  background: #001457;
  min-height: 33px;
}

.edit-button:hover {
  opacity: .8;
}

.delete {
  color: #fff;
  background: #e65932;
  min-height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete:hover {
  opacity: .8;
}

.Button:focus{
  outline: none;
}

.Button:disabled {
  opacity: .35;
}

button {
  border: none;
  color: #001457;
  background: none;
}

button:hover {
  cursor: pointer;
}
