.Admin {
  /* max-width: 1600px;
  margin: 0 auto; */
}

.Admin-header {
  /* padding: 1rem;
  border-bottom: 2px solid #ddd;
  margin-bottom: 2rem; */
}

.Admin-filters {
  /* display: flex; */
}

thead tr:hover {
  background-color: transparent;
}

tr:hover {
  background-color: #fbfbfb;
}

tr {
  padding: 1rem;
}

.react-select__control:hover {
  cursor: pointer;
}

.Admin-filter {
  /* flex: 1;
  padding: 0 1rem; */
}
