.AdminQuizzes button {
  background: #eee;
  color: #000;
  box-shadow: inset 0 0 0 1px #000;
  cursor: pointer;

  font: inherit;
  display: block;
  border: none;
  padding: 0.5rem 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.AdminQuizzes button:hover {
  background: #000;
  color: #fff;
}

.AdminQuizzes textarea,
.AdminQuizzes input {
  width: 100%;
  border: none;
  box-shadow: inset 0 0 0 1px #bbb;
  background: #fff;
  padding: 0.5rem;
}

.AdminQuizzes label {
  margin: 0;
  font-size: 1rem;
  display: block;
}

.delete-quiz-wrapper {
  padding: 1rem;
  margin-top: 1rem;
  border: 2px solid red;
}
