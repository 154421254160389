.quiz-builder-answers {
  border: 1px solid #000;
  padding: 0.5rem;
  margin: 1rem 0;
}

.quiz-builder-answer-row {
  background: #fff;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
}

.quiz-builder-section {
  border: 1px solid #000;
  padding: 1rem;
  background: #fff;
  margin-bottom: 1rem;
}
