.Modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 1rem;
  overflow-y: auto;
  -webkit-overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.end {
  padding: 6rem 1rem;
  text-align: center;
}

.Modal-content {
  position: relative;
  background: #fff;
  padding: 2.5rem;
  max-width: 26rem;
  margin: 0 auto;
}

.Modal-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  margin-bottom: 15px;
  box-shadow: inset 0 -4px 0 0 #ddd;
  color: grey;
}

.Modal-menu li {
  display: block;
  margin-right: 1rem;
}

.Modal-menu li a {
  display: block;
  padding: 1rem 0;
  font-weight: 600;
  color: #ddd;
  box-shadow: inset 0 -4px 0 0 #ddd;
}

.Modal-menu li a:hover {
  color: #001457;
}

.Modal-menu li a.active {
  box-shadow: inset 0 -4px 0 0 #001457;
  color: #001457;
}

.Modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 2.25rem;
  text-align: center;
  width: 2.25rem;
  line-height: 2.25rem;
  color: rgba(0, 20, 87, 0.35);
  text-decoration: none;
}

.Modal-close:hover {
  color: rgba(0, 20, 87, 1);
}
input {
  border-radius: 0;
}

input[type="text"][value]:not([value=""]) {
  border-bottom-color: #001457;
  color: #001457;
  font-weight: bold;
}
